












































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import "@/types";

@Component({
  metaInfo: {
    title: "Yeni Başvuru Oluştur"
  }
})
export default class AppApplyView extends Vue {
  requiredRule = [(v: string) => !!v || "Zorunlu alan"];
  email = "";
  emailRules = [
    this.requiredRule[0],
    (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
  ];
  pastApplications: [
    {
      id: string;
      dateCreated: Date;
      status: "accepted" | "rejected" | "inprogress" | "inevaluation";
    }
  ] = [
    {
      id: "1",
      dateCreated: new Date("2021-01-12 13:00:00"),
      status: "accepted"
    }
  ];

  scholarship: Scholarship | null = null;

  eduLevels = [
    "Okul Öncesi",
    "İlköğretim",
    "Lise",
    "Üniversite",
    "Tıp/Diş Hekimliği"
  ];

  tcknRules = [
    this.requiredRule[0],
    (v: string) =>
      /^[1-9]{1}[0-9]{9}[02468]{1}$/.test(v) || "Geçersiz TC kimlik numarası"
  ];

  gender = ["Kadın", "Erkek"];

  birthDateRules = [
    this.requiredRule[0],
    (v: string) =>
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
        v
      ) || "Geçersiz doğum tarihi (Örn: 13.08.1990)"
  ];

  schoolTypes = [
    "Okul Öncesi",
    "İlköğretim",
    "Lise",
    "Üniversite (2 Yıllık)",
    "Üniversite (4 Yıllık)",
    "Üniversite (5 Yıllık)",
    "Tıp/Diş Hekimliği"
  ];

  schoolEntryYearRules = [
    this.requiredRule[0],
    (v: string) =>
      /(?:(?:18|19|20|21)[0-9]{2})/.test(v) || "Geçersiz tarih (Örn: 2010)"
  ];

  residentialTypes = ["Aile Yanı", "Ev", "Devlet Yurdu", "Özel Yurt"];

  phoneRules = [
    this.requiredRule[0],
    (v: string) =>
      /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(v) ||
      "Geçersiz numara (Örn: 0532 111 11 11)"
  ];

  chronicIllness = ["Var", "Yok"];

  isAlive = ["Hayatta", "Hayatta Değil"];

  get motherIsAlive() {
    return this.appForm.family.mother.isAlive === "Hayatta";
  }

  get fatherIsAlive() {
    return this.appForm.family.father.isAlive === "Hayatta";
  }

  salaryRules = [
    this.requiredRule[0],
    (v: string) =>
      /^[0-9]+(,[0-9]{1,2})?$/.test(v) || "Geçersiz değer (Örn: 3000)"
  ];

  ibanRules = [
    this.requiredRule[0],
    (v: string) =>
      /TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?/.test(
        v
      ) || "Geçersiz IBAN numarası (Örn: TR320010009999901234567890)"
  ];

  appFormIsValid = false;

  appForm = {
    eduLevel: "",
    student: {
      tckn: "",
      fullName: "",
      gender: "",
      birthDate: "",
      birthPlace: "",
      schoolType: "",
      schoolName: "",
      schoolEntryYear: "",
      residentialType: "",
      address: "",
      phone: "",
      email: "",
      chronicIllness: ""
    },
    family: {
      mother: {
        fullName: "",
        isAlive: "",
        job: "",
        salary: "",
        eduLevel: "",
        phone: ""
      },
      father: {
        fullName: "",
        isAlive: "",
        job: "",
        salary: "",
        eduLevel: "",
        phone: ""
      },
      monthlyIncome: "",
      siblingsNumber: null,
      studentSiblingsNumber: null,
      specialCase: ""
    },
    bankName: "",
    accountName: "",
    iban: ""
  };

  get member() {
    return this.$store.getters["scholarship/member"];
  }

  async submitAppForm() {
    const appForm: HTMLFormElement = this.$refs.appForm as HTMLFormElement;

    if (!appForm.validate()) {
      return;
    }

    try {
      const newApp = {
        scholarshipId: this.scholarship?.id,
        member: this.member,
        form: this.appForm,
        files: [],
        status: "inprogress",
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        dateUpdated: null
      };

      const newAppRef = await firebase
        .firestore()
        .collection("/apps/kahev-org/scholarshipApps")
        .add(newApp);

      this.$router.push({
        name: "scholarship-edit",
        params: { id: newAppRef.id }
      });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Burs başvuru formu kaydedilemedi."
      });

      console.error(err);
    }
  }

  async getScholarship() {
    const appUrlCode = this.$route.params.urlCode;

    try {
      const qs = await firebase
        .firestore()
        .collection("/apps/kahev-org/scholarships")
        .where("urlCode", "==", appUrlCode)
        .get();

      if (!qs.empty) {
        const doc = qs.docs[0];
        this.scholarship = doc.data() as Scholarship;
        this.scholarship.id = doc.id;
      }
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Burs listesi alınamadı!"
      });

      console.error(err);
    }
  }

  async mounted() {
    await this.getScholarship();
  }
}
